import { SimpleQuery } from "@ollie-sports/firebase-lift";
import { Evaluation, PlayerId } from "@ollie-sports/models";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function evaluation__client__getEvaluationsForPlayerIdsSubscription(p: {
  playerIds: PlayerId[];
}): BifrostSubscription<Evaluation[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.Evaluation.multiQuerySubscription({
      mergeProcess: {
        orderBy: { sortKey: "sentAtMS", dir: "asc" }
      },
      queries: p.playerIds.map(playerId => {
        const pendingQuery: SimpleQuery<Evaluation> = {
          where: [{ playerId: ["==", playerId] }],
          orderBy: [{ dir: "desc", pathObj: { sentAtMS: true } }]
        };
        return pendingQuery;
      })
    })
  );
}
// i18n certified - complete
