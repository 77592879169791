import { Evaluation, EvaluationPlayer, TeamId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function evaluation__client__getEvaluationsForTeamSubscription(p: { teamId: TeamId }): BifrostSubscription<Evaluation[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(h.Evaluation.querySubscription({ where: [{ teamId: ["==", p.teamId] }] }));
}

// i18n certified - complete
