import { EvaluationCoach } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { ObjectKeys } from "../../utils";

export async function evaluation__client__saveEvaluationCoach(p: { evaluation: EvaluationCoach }) {
  const { ollieFirestoreV2: h, app } = getUniversalHelpers();

  const oldEvaluation = await h.Evaluation.getDoc(p.evaluation.id);

  if (oldEvaluation) {
    const imagesToDelete = Object.values(oldEvaluation.imageUris ?? {}).filter(
      uri => !Object.values(p.evaluation.imageUris ?? {}).find(otherUri => otherUri === uri)
    );
    for (let i = 0; i < imagesToDelete.length; i++) {
      try {
        const deleteTask = app.storage().refFromURL(imagesToDelete[i]).delete();
        await deleteTask;
      } catch (e) {
        // Do nothing cause we don't want to hold things up
      }
    }
  }

  await h.Evaluation.set({
    id: p.evaluation.id,
    doc: _.cloneDeep(p.evaluation)
  });
}

// i18n certified - complete
