import { SimpleQuery } from "@ollie-sports/firebase-lift";
import { AccountId, Evaluation } from "@ollie-sports/models";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function evaluation__client__getEvaluationsForCoachAccountIdSubscription(p: {
  coachAccountId: AccountId;
}): BifrostSubscription<Evaluation[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftQuerySubToBifrostSub(
    h.Evaluation.querySubscription({
      where: [{ coachAccountId: ["==", p.coachAccountId] }]
    })
  );
}

// i18n certified - complete
