import { Evaluation, EvaluationCoach, EvaluationPlayer, OrgId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function evaluation__client__getEvaluationsForOrg(p: {
  orgId: OrgId;
  startDateMS: number;
  endDateMS: number;
  type: "coach" | "player";
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let evaluations: EvaluationCoach[] | EvaluationPlayer[] = [];
  try {
    evaluations =
      p.type === "coach"
        ? ((
            await h.Evaluation.query({
              where: [
                {
                  type: ["==", "coach"]
                },
                {
                  orgId: ["==", p.orgId]
                },
                {
                  sentAtMS: [">", p.startDateMS]
                },
                {
                  sentAtMS: ["<", p.endDateMS]
                }
              ]
            })
          ).docs.filter(evaluation => evaluation.type === "coach") as EvaluationCoach[])
        : ((
            await h.Evaluation.query({
              where: [
                {
                  type: ["==", "player"]
                },
                {
                  orgId: ["==", p.orgId]
                },
                {
                  sentAtMS: [">", p.startDateMS]
                },
                {
                  sentAtMS: ["<", p.endDateMS]
                }
              ]
            })
          ).docs.filter(evaluation => evaluation.type === "player") as EvaluationPlayer[]);
  } catch (e) {
    console.log(e);
  }
  return evaluations;
}

// i18n certified - complete
