import { Evaluation, EvaluationCoach, EvaluationId, EvaluationPlayer, OrgId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function evaluation__client__getEvaluation(p: { evaluationId: EvaluationId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return await h.Evaluation.getDoc(p.evaluationId);
}

// i18n certified - complete
